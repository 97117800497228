import { AppState, Auth0Provider, AuthorizationParams } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import env from "@beam-australia/react-env";
import { getOrganizationId } from "./Infrastructure/Services/Auth0OrganizationService";

export const Auth0ProviderWithNavigate = ({ children }: { children: React.ReactNode }) => {
    const navigate = useNavigate();

    const domain = env("AUTH0_DOMAIN");
    const clientId = env("AUTH0_CLIENT_ID");
    const redirectUri = env("AUTH0_CALLBACK_URL");
    const audience = env("AUTH0_AUDIENCE");
    const scopes = env("AUTH0_LOGIN_SCOPES");

    const onRedirectCallback = (appState: AppState | undefined) => {
        const returnTo = appState?.returnTo?.replace(env("ROUTER_BASE_PATH"), "");
        navigate(returnTo ?? window.location.pathname);
    };

    if (!(domain && clientId && redirectUri && audience && scopes)) {
        return null;
    }

    const getAuthorizationParams = () : AuthorizationParams => {
        const authorizationParams: AuthorizationParams = {
            audience,
            scope: `openid profile email ${scopes}`,
            redirect_uri: `${window.location.origin}${env("ROUTER_BASE_PATH")}/redirect`,
        };
    
        const organizationId = getOrganizationId();
        if (organizationId) {
            authorizationParams.organization = organizationId;
        }
        return authorizationParams;
    }

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            authorizationParams={getAuthorizationParams()}
            onRedirectCallback={onRedirectCallback}>
            {children}
        </Auth0Provider>
    );
};
